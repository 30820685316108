<template>
  <v-card>
    <v-card-title>
      {{ this.mainTitle }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <!-- https://vuetifyjs.com/en/components/data-tables/ -->
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      :dense="dense"
      multi-sort
      show-select
      v-model="selected"
      :single-select="singleSelect"
      item-key="index"
      @input="handleRowClick()"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ['mainTitle', 'headers', 'data', 'dense'],
  data() {
    return {
      selected: [],
      singleSelect: true,
      search: ''
    };
  },

  //https://stackoverflow.com/a/64095590/15546776
  methods: {
    handleRowClick() {
      console.log('handleRowClick');
      this.$emit('selectItemsChanged', this.selected);
    }
  }
};
</script>
