<template>
  <!-- <div class="extremeLog"> -->
    <!-- <h1>This is an about page</h1> -->
    <ExtremeLogComp/>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
import ExtremeLogComp from '@/components/ExtremeLogComp.vue'

export default {
  name: 'ExtremeLog',
  components: {
    ExtremeLogComp
  }
}
</script>
