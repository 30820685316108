export const showDirPickerAndGetAllFilesOfType = async fileExtension => {
  console.log(
    'showDirPickerAndGetAllFilesOfType(). fileExtension = ' + fileExtension
  );
  try {
    var dirHandle = await window.showDirectoryPicker();
    let files = await listAllFilesNFoldersRecursively(dirHandle);

    //only get the files in the list, not the directories
    files = files.filter(file => file.kind === 'file');

    //now only get the specific files from the fileExtension
    files = files.filter(file => file.name.split('.').pop() === fileExtension);

    return files;
  } catch (e) {
    console.log(e);
  }
};

export const getAllFilesAndFoldersRecursively = async () => {
  console.log('getAllFilesHandlesInFolderRecursively()');
  try {
    var dirHandle = await window.showDirectoryPicker();
    let allFiles = await listAllFilesNFoldersRecursively(dirHandle);

    //only get the files in the list, not the directories
    //   allFiles = allFiles.filter(file => file.kind === 'file');

    return allFiles;
  } catch (e) {
    console.log(e);
  }
};

//Note: this function isn't exported. Only accessed locally within this file
const listAllFilesNFoldersRecursively = async dirHandle => {
  //used so we can later loop through the folder the file
  //is contained in and find any associated files. ie. .cs and its .cs.meta file
  let currentDirHandle = dirHandle;

  //for debugging so we know what folder the file is in. Easy to see in the Vue chrome tool
  let currentDirName = dirHandle.name;

  const files = [];
  for await (let [name, handle] of dirHandle) {
    const { kind } = handle;
    if (handle.kind === 'directory') {
      //add the directory to the list too
      files.push({ name, handle, kind });

      //and then recursively get the files + folders in that directory
      files.push(...(await listAllFilesNFoldersRecursively(handle)));

    //   currentDirHandle = handle;
    //   currentDirName = handle.name;
    } else {
      //   files.push({ name, handle, kind });
      files.push({ name, handle, kind, currentDirHandle, currentDirName });
    }
  }
  return files;
};

export const getHandleOfSingleFileInFolder = async (dirHandle, fileName) => {
  for await (let [name, handle] of dirHandle) {
    console.log(`getHandleOfSingleFileInFolder() name: ${name}`);
    console.log(`getHandleOfSingleFileInFolder() handle: `, handle);
    if (handle.kind === 'file') {
      //we found the file
      if (name === fileName) {
        return handle;
      }
    }
  }
  //file not found
  return null;
};

export const countLinesInFiles = async file => {
  console.log('countLinesInFiles(). fileData = ' + file);

  let fileData = await file.handle.getFile();
  let fileText = await fileData.text();

  //Note: this also counts any "\n" written in a string. Need to check for carriage return instead
  //https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
  let lines = fileText.split(/\r\n|\r|\n/);
  return lines.length;
};

// export const getFilesWithExtensionFromArray = (fileNFolderArray, extention) => {
//   let allFilesWithExtension = fileNFolderArray.filter(file => {
//     let fileExtention = file.name.substr(file.name.length - extention.length);
//     console.log('fileExtention = ' + fileExtention);
//     return fileExtention === extention;
//     // file.name.substr(file.name.length - 7) === '.cs.meta'
//   });

//   return allFilesWithExtension;
// };
