<template>
  <v-container>
    <!-- ============================================================= -->
    <!-- Page title and subtitle -->
    <!-- ============================================================= -->
    <v-row class="text-center">
      <v-col>
        <h2>Entry Point (Beta)</h2>
        <p>Find calls to Awake Start and Update from GameObjects in your scenes</p>
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- Choose Asset Folder button -->
    <!-- ============================================================= -->

    <v-row class="text-center">
      <v-col class="mb-2">
        <v-btn @click="onChooseAssetFolderBtn" depressed color="primary">
          Choose Asset folder
        </v-btn>
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- The DataTable containing the list of Unity scenes -->
    <!-- ============================================================= -->

    <v-row class="text-center">
      <v-col class="mb-2 mt-2">
        <DataTableComp
          v-if="scenesDataTableEntriesArray.length > 0"
          :mainTitle="'Scenes: ' + scenesDataTableEntriesArray.length"
          :headers="scenesDataTableCompHeaders"
          :data="scenesDataTableEntriesArray"
          :dense="true"
          @selectItemsChanged="
            selectedScenes => (this.selectedScenes = selectedScenes)
          "
        >
        </DataTableComp>
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- Scan Selected Scenes button -->
    <!-- ============================================================= -->

    <v-row class="text-center">
      <v-col class="mb-2 mt-2">
        <v-btn
          @click="onScanSelectedScenes"
          depressed
          color="primary"
          v-if="selectedScenes.length > 0"
        >
          Scan selected scene
        </v-btn>
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- DataTable containing the list of Unity built-in functions -->
    <!-- ============================================================= -->

    <v-row class="text-center">
      <v-col class="mb-2">
        <p>
          <small>{{ progressText }}</small>
        </p>
        <DataTableComp
          v-if="showFuncsDataTable === true"
          :mainTitle="
            'Event Functions: ' + unityFuncsDataTableEntriesArray.length
          "
          :headers="unityFuncsDataTableHeaders"
          :data="unityFuncsDataTableEntriesArray"
          :dense="true"
        >
        </DataTableComp>
      </v-col>
    </v-row>

    <!-- <TreeViewComp :treeItems="treeViewItemsArray"> </TreeViewComp> -->

    <!-- Just to easily debug the scenesArray object -->
    <!-- <v-row class="text-center">
      <v-col class="mb-2 mt-2">
        <textarea
          rows="2000"
          cols="2000"
          v-model="scenesArrayAsJson"
          style="font-size: 12px"
        >
        </textarea>
      </v-col>
    </v-row> -->

    <!-- <v-row class="text-center">
      <v-col class="mb-2">
        <DataTableComp
          :mainTitle="'Heavy functions found: ' + numOfUnityFuncsFound"
          :headers="unityFuncsDataTableHeaders"
          :data="unityFuncsDataTableEntriesArray"
        >
        </DataTableComp>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
// import { showDirPickerAndGetAllFilesOfType } from '@/js/fileNFolderUtils';
import {
  getAllFilesAndFoldersRecursively,
  //   getFilesWithExtensionFromArray,
  //   getHandleOfFileInFolder
  getHandleOfSingleFileInFolder
} from '@/js/fileNFolderUtils';
import {
  //   tryParseYamlJSONStyleObj,
  //   getFirstNonEmptyCharInString,
  //   getIndexOfFirstNonEmptyCharInString,
  //   tryParseNumber,
  //   getGUIDFromUnityMetaFile,
  checkIfCodeFileHasFunctions
} from '@/js/parsers';

// import { getFirstNonEmptyCharInString } from '@/js/parsers';
// import { getIndexOfFirstNonEmptyCharInString } from '@/js/parsers';
// import { tryParseNumber } from '@/js/parsers';
// import { parseObjIDFromYamlFile } from '@/js/parsers';

// import TreeViewComp from '@/components/TreeViewComp.vue';
// import { Analytics } from 'aws-amplify';
import Consts from '@/js/constants';

//parses yaml to a js object
//https://github.com/nodeca/js-yaml
import yaml from 'js-yaml';
import DataTableComp from '@/components/DataTableComp.vue';

export default {
  name: 'EntryPoint',

  components: {
    // TreeViewComp,
    DataTableComp
  },

  created() {
    this.resetValues();
  },

  // =============================================================
  // data object
  // =============================================================
  data: () => ({
    allFilesAndFoldersHandles: null,
    allFileHandles: null,
    allUnitySceneFilesHandles: null, //NOTE: MAY GET RID OF THIS AND JUST GRAB A HANDLE TO ALL FILES IN THE ASSETS FOLDER
    // scenesArray: [],
    allScenesArray2: [],
    // allScenesAsHierarchyArray: [],
    scenesArrayAsJson: null,
    // allScriptObjsFromSceneFiles: [],
    allCsMetaFiles: [],
    // allGOsFromSceneFiles: [],
    allMonoBehavioursArray: [],
    // allCSMetaFileGUIDAndFileHandle: {}
    treeViewItemsArray: [],

    // numOfUnityScenesFound: 0,
    scenesDataTableCompHeaders: [
      {
        text: '#',
        value: 'index'
      },
      {
        text: 'Scene',
        value: 'sceneName'
      }
    ],
    scenesDataTableEntriesArray: [],
    selectedScenes: [],

    // numOfUnityFuncsFound: 0,
    unityFuncsDataTableHeaders: [
      {
        text: '#',
        value: 'index'
      },
      {
        text: 'Scene',
        value: 'sceneName'
      },
      {
        text: 'GameObject',
        value: 'gameObject'
      },
      {
        text: 'Script File',
        value: 'scriptFile'
      },
      {
        text: 'Line',
        value: 'lineNum'
      },
      {
        text: 'Function',
        value: 'function'
      }
    ],
    unityFuncsDataTableEntriesArray: [],
    showFuncsDataTable: false,
    progressText: ''
    //placehold values added just as an example. these get deleted
    // unityFuncsDataTableEntriesArray: [
    //   {
    //     entryNumber: 1,
    //     sceneName: 'MainGame',
    //     gameObject: 'Scripts holder',
    //     scriptFile: 'Main.cs',
    //     function: 'Awake'
    //   },
    //   {
    //     entryNumber: 2,
    //     sceneName: 'Menu',
    //     gameObject: 'Play Button',
    //     scriptFile: 'PlayButton.cs',
    //     function: 'Start'
    //   }
    // ]
  }),

  // =============================================================
  // methods object
  // =============================================================
  methods: {
    resetValues() {
      //clear out the array
      //   this.unityFuncsDataTableEntriesArray = [];
      //   this.numOfUnityFuncsFound = 0;
      this.allFilesAndFoldersHandles = null;
      this.allFileHandles = null;
      this.allUnitySceneFilesHandles = null;
      //   this.scenesArray = [];
      //   this.allScenesArray2 = [];
      this.allScenesAsHierarchyArray = [];
      this.scenesArrayAsJson = null;
      //   this.allScriptObjsFromSceneFiles = [];
      this.allCsMetaFiles = []; //NOTE: maybe able to remove this later, and just keep it in the function go GC can clean it up
      //   this.allGOsFromSceneFiles = [];
      this.allMonoBehavioursArray = [];
      //   this.allCSMetaFileGUIDAndFileHandle = {};

      this.treeViewItemsArray = [];

      //the scenes table
      //   this.numOfUnityScenesFound = 0;
      this.scenesDataTableEntriesArray = [];
      this.selectedScenes = [];

      //the unity functions table
      //   this.numOfUnityFuncsFound = 0;
      this.unityFuncsDataTableEntriesArray = [];
      this.showFuncsDataTable = false;
    },

    clearValuesWhenScanSceneClicked() {
      //so we don't try to re-list unity functions in scenes we've
      //already scanned and added to the 2nd DataTable component
      this.allScenesArray2 = [];
    },

    // =============================================================
    // Called when Asset Folder has been selected from
    // file / folder select window
    // =============================================================
    async onChooseAssetFolderBtn() {
      this.resetValues();
      await this.getAllFilesInSelectedFolder();
      this.displayUnitySceneFilesInTable();
      //   await this.getHandleOfMainFileForEachMetaFile();
      //   await this.parseGuidFromCSMetaFileAndAddToMetaFileObj();
      //   await this.fixUnitySceneFilesForJsToYamlParsingAndParse();
      //   this.addCsFileHandleAndNameToMScriptObjs();
      //   await this.parseCsFilesInScenesAndAddFuncNamesToMScriptObjs();
      //   this.addComponentObjsUnderGOs();
      //   // this.fillTreeViewArrayForDisplayingData();
      //   this.loopOverGOFuncsAndfillDataTableArray();
      //   //   this.displayScenesArray();
      //   console.log('this.scenesArray', this.allScenesArray2);
    },

    async getAllFilesInSelectedFolder() {
      //shows the folder select popup to the user.
      this.allFilesAndFoldersHandles = await getAllFilesAndFoldersRecursively();

      this.allFileHandles = this.allFilesAndFoldersHandles.filter(
        file => file.kind === 'file'
      );

      //just grab the unity scene files
      this.allUnitySceneFilesHandles = this.allFilesAndFoldersHandles.filter(
        file => file.name.split('.').pop() === 'unity'
      );
    },

    displayUnitySceneFilesInTable() {
      for (const file of this.allUnitySceneFilesHandles) {
        let sceneEntryObj = {
          index: this.scenesDataTableEntriesArray.length + 1,
          sceneName: file.name,
          sceneFileHandle: file
        };
        console.log(
          'displayUnitySceneFilesInTable(); sceneEntryObj = ',
          sceneEntryObj
        );
        this.scenesDataTableEntriesArray.push(sceneEntryObj);
      }
    },

    // =============================================================
    // Called when Scan Selected Scenes btn is pressed
    // =============================================================
    async onScanSelectedScenes() {
      this.showFuncsDataTable = true;
      this.clearValuesWhenScanSceneClicked();
      await this.getHandleOfMainFileForEachMetaFile();
      await this.parseGuidFromCSMetaFileAndAddToMetaFileObj();
      await this.fixUnitySceneFilesForJsToYamlParsingAndParse([
        //using index 0 because selectedScenes is an array filled by the DataTable component.
        //Currently users can only select a single scene from the table
        this.selectedScenes[0].sceneFileHandle
      ]);
      this.addCsFileHandleAndNameToMScriptObjs();
      await this.parseCsFilesInScenesAndAddFuncNamesToMScriptObjs();
      this.addComponentObjsUnderGOs();
      this.loopOverGOFuncsAndfillDataTableArray();
      this.progressText = '';
      console.log('this.scenesArray', this.allScenesArray2);
    },

    //For example, if we have a file obj with the name test.cs.meta we want to add the handle
    //of it's main file (test.cs) to the file object. Eg, object would look like this:
    //{
    //  fileName: test.cs.meta
    //  associatedFileName: test.cs
    //}
    async getHandleOfMainFileForEachMetaFile() {
      console.log('getHandleOfMainFileForEachMetaFile() called');
      for (let i = 0; i < this.allFileHandles.length; i++) {
        let fileName = this.allFileHandles[i].name;
        let fileDirHandle = this.allFileHandles[i].currentDirHandle;

        console.log(`this.allFileHandles[${i}].name = ${fileName}`);
        this.progressText = `Looking for .cs.meta files: ${fileName}`;

        //only getting the cs files associated with the .cs.meta files here
        if (fileName.endsWith('.cs.meta')) {
          console.log(`${fileName} is a .cs meta file`);
          this.progressText = `Found .cs.meta file`;

          //remove the .meta from the end of the file name string
          let fileNameToFind = fileName.slice(0, -5);
          //   console.log('file to find = ' + fileNameToFind);
          console.log(
            `Looking for ${fileNameToFind} in folder ${fileDirHandle.name}`
          );
          this.progressText = `Looking for ${fileNameToFind} in folder ${fileDirHandle.name}`;

          let mainCSfileHandle = await getHandleOfSingleFileInFolder(
            fileDirHandle,
            fileNameToFind
          );
          console.log('cs file handle = ', mainCSfileHandle);

          if (mainCSfileHandle != null) {
            this.allFileHandles[i].associatedFileHandle = mainCSfileHandle;

            //Just for debugging so we can see the associated file name in the Vue chrome tool
            this.allFileHandles[i].associatedFileName = fileNameToFind;
          }

          //so we can just loop over the cs files later instead of all files
          this.allCsMetaFiles.push(this.allFileHandles[i]);
        }
      }
    },

    //calls the parsing function to parse the .meta file and grab the guid.
    //then adds that guid to the .meta file obj in the allCsMetaFiles array
    async parseGuidFromCSMetaFileAndAddToMetaFileObj() {
      for (let i = 0; i < this.allCsMetaFiles.length; i++) {
        let fileData = await this.allCsMetaFiles[i].handle.getFile();
        let fileText = await fileData.text();

        let metaFileObj = yaml.load(fileText);
        console.log(
          `parseGuidFromCSMetaFileAndAddToMetaFileObj() metaFileObj.guid = ${metaFileObj.guid}`
        );

        this.progressText = `Parsing guid from ${this.allCsMetaFiles[i].name}`;

        // let guid = getGUIDFromUnityMetaFile(fileText);
        // console.log(`parseGuidFromCSMetaFileAndAddToMetaFileObj() guid = ${guid}`);
        this.allCsMetaFiles[i].guid = metaFileObj.guid;
      }
    },

    // async getAllScriptFileHandlesFromMetaFileGUID() {
    //get the last 8 characters of the of the file name and check
    //if its equal to '.cs.meta'
    //   let allCsMetaFiles = this.allFileHandles.filter(
    //     file => {
    //         let fileExtention = file.name.substr(file.name.length - 8);
    //         console.log('fileExtention = ' + fileExtention);
    //         return fileExtention === '.cs.meta';
    //         // file.name.substr(file.name.length - 7) === '.cs.meta'
    //     }
    //   );

    //   for(let i = 0; i < allCsMetaFiles.length; i++){
    //       console.log(`allCsMetaFiles[${i}]`, allCsMetaFiles[i]);
    //   }

    //   this.allCsMetaFiles = getFilesWithExtensionFromArray(
    //     this.allFileHandles,
    //     '.cs.meta'
    //   );

    //   for (let i = 0; i < this.allCsMetaFiles.length; i++) {
    //     let fileHandle = this.allCsMetaFiles[i].handle;
    //     let handleOfDirFileIsIn = this.allCsMetaFiles[i].currentDirHandle;
    //     let fileName = this.allCsMetaFiles[i].name;
    //     let fileData = await fileHandle.getFile();
    //     console.log(
    //       'getAllScriptFileHandlesFromMetaFileGUID() file handle: ',
    //       fileHandle
    //     );
    //     let fileText = await fileData.text();
    //     let guid = getGUIDFromUnityMetaFile(fileText);
    //     // this.allCSMetaFileGUIDAndFileHandle[guid] = "testFileHandle";
    //     let csFileHandle = await getHandleOfFileInFolder(
    //       handleOfDirFileIsIn,
    //       fileName.slice(0, -5)
    //     );
    //     this.allCSMetaFileGUIDAndFileHandle[guid] = csFileHandle;
    //   }
    // },

    async fixUnitySceneFilesForJsToYamlParsingAndParse(unitySceneFileHandles) {
      console.log(
        'fixUnitySceneFilesForJsToYamlParsingAndParse() unitySceneFileHandles = ',
        unitySceneFileHandles
      );
      //   for (const file of this.allUnitySceneFilesHandles) {
      for (const file of unitySceneFileHandles) {
        //grab the scene file text here but don't save it to a persistent variable. so garbage collection cleans up
        //after the function exits. some Unity project can have 1000's of files.
        let fileData = await file.handle.getFile();
        let fileText = await fileData.text();

        //split each line into an array
        let fileLinesArr = fileText.split(/\r\n|\r|\n/);

        //Removing the top 2 lines as these are Yaml1.1 which yaml-js can't parse as it parses v2.0
        //%YAML 1.1
        //%TAG !u! tag:unity3d.com,2011:
        //TODO: USING 2 LINES HERE TO REMOVE 2 ELEMENTS FROM ARRAY, FIND A SINGLE WAY TO DO THIS
        fileLinesArr.shift();
        fileLinesArr.shift();

        // console.log('fileLinesArr', fileLinesArr);

        for (let i = 0; i < fileLinesArr.length; i++) {
          //Eg: --- !u!1 &1331370700
          let line = fileLinesArr[i];

          this.progressText = `Adjusting ${unitySceneFileHandles.name} scene file for yaml parsing. Reading line: ${line}`;

          //Convert a line like this: --- !u!4 &1331370701 to 2 json style lines
          //like below so they can be parsed together with the rest of the scene obj:
          //unityObjClass: 4
          //uniqueObjIDInFile: 1331370701
          //and move them under the main component object. Eg:
          //OcclusionCullingSettings:
          //  unityObjClass: 4
          //  uniqueObjIDInFile: 1331370701
          if (line.startsWith('---')) {
            //cut off the first 4 characters, not needed.
            //Eg. will look like this: !u!1 &1331370700
            line = line.substring(4);

            let wordArray = line.split(' ');

            //two spaces needed at the front so they line up correctly with the other
            //child objects in the component. Space indents matter as it dictates if
            //something is a child of less indented lines or not
            let unityObjClassLine =
              '  unityObjClass: ' + wordArray[0].substring(3);
            let uniqueObjIDInFileLine =
              '  uniqueObjIDInFile: ' + wordArray[1].substring(1);

            //insert the new lines into the array and. 2nd arg of splice(); '0' is the num of entries to delete
            //https://stackoverflow.com/a/586189/15546776
            fileLinesArr.splice(i + 2, 0, unityObjClassLine);
            fileLinesArr.splice(i + 3, 0, uniqueObjIDInFileLine);

            //now set the line to three hypens as these are the yaml delimiters
            //in a multi yaml file. Needs to be done as js-yaml cannot parse
            //custom lines like this --- !u!114 &1880610200
            fileLinesArr[i] = '---';

            //skip the loop forward as no need to check the lines we just inserted
            i += 2;
          }
        }

        // this.parseUnitySceneFileArray(file, fileLinesArr);

        // this.parseUnitySceneFileArray_2(file, fileText);
        await this.parseUnitySceneFileArray_2(file, fileLinesArr);
      }
    },

    //parse the fixed yaml scene file using the js-yaml package
    // async parseUnitySceneFileArray_2(file, fileText) {
    async parseUnitySceneFileArray_2(file, fileLinesArr) {
      //TODO: most of this can be put into parsers.js
      //split each line into an array
      //UPTO HERE
      //   let fileLinesArr = fileText.split(/\r\n|\r|\n/);

      //   let fixedLinesArray = [];

      //some lines need to be skipped due to them not being custom unity yaml
      //   for (let i = 0; i < fileLinesArr.length; i++) {
      //     let line = fileLinesArr[i];

      //     //remove the text after the --- as js-yaml can't parse these.
      //     //Eg: --- !u!1 &705507993
      //     //Note these lines have already been moved these into the first lines
      //     //of the yaml object in the scene file in the 'fix scene file' function
      //     // if (line.startsWith('---')) {
      //     //   line = '---';
      //     // }
      //     //these lines throw errors as they are yaml 1.1 and the
      //     //parser used below is for 1.2
      //     if (line.includes('%TAG')) continue;
      //     else if (line.includes('%YAML')) continue;

      //     fixedLinesArray.push(line);
      //   }

      //   let reJoinedFileLines = fixedLinesArray.join('\n');

      //parse all yaml doc object in to the scene file
      //and push to the array
      //https://github.com/nodeca/js-yaml
      //   yaml.loadAll(reJoinedFileLines, unitySceneFileObj => {
      //     sceneFileObjsArray.push(unitySceneFileObj);
      //   });

      let reJoinedFileLines = fileLinesArr.join('\n');
      let sceneFileObjsArray = [];

      //parse all the separate yaml objects in the scene files
      //and add to the scene objects array
      yaml.loadAll(reJoinedFileLines, unitySceneFileObj => {
        sceneFileObjsArray.push(unitySceneFileObj);

        // console.log(
        //   'parseUnitySceneFileArray_2(); unitySceneFileObj: ',
        //   unitySceneFileObj
        // );
        // console.log('parseUnitySceneFileArray_2(); unitySceneFileObj.name: ', unitySceneFileObj.key);
        // console.log('parseUnitySceneFileArray_2(); unitySceneFileObj.MonoBehaviour: ', unitySceneFileObj.MonoBehaviour);

        //add these scene file object to a separate array if its a MonoBehaviour
        //https://dmitripavlutin.com/check-if-object-has-property-javascript/#1-hasownproperty-method
        if (unitySceneFileObj.MonoBehaviour != undefined) {
          //   console.log(
          //     'parseUnitySceneFileArray_2(); unitySceneFileObj.MonoBehaviour.m_Script: ',
          //     unitySceneFileObj.MonoBehaviour.m_Script
          //   );

          //TODO: can delete m_Script objects array eventually as we'll just need to grab
          //the parent MonoBehaviour object into an array instead.
          //   this.allScriptObjsFromSceneFiles.push(
          //     unitySceneFileObj.MonoBehaviour.m_Script
          //   );
          this.allMonoBehavioursArray.push(unitySceneFileObj.MonoBehaviour);
        }
        // //add these scene file object to a separate array if its a GameObject
        // else if (unitySceneFileObj.GameObject != undefined) {
        //   //Mainly for debugging so we can see which scene the GO belongs to.
        //   unitySceneFileObj.GameObject.sceneName = file.name;
        //   this.allGOsFromSceneFiles.push(unitySceneFileObj.GameObject);
        // }

        // if('m_Script'){
        // console.log('parseUnitySceneFileArray_2(); m_Script: ', unitySceneFileObj.m_Script);
        //     // this.allMonoBehavioursArray
        // }
      });

      //creating an object so we can show the scene name as a kvp
      //and then the array of objects below it
      let newSceneFileObj = {
        sceneName: file.name,
        objsInSceneArray: sceneFileObjsArray
      };
      this.allScenesArray2.push(newSceneFileObj);
    },

    //TODO: can likely delete this function soon as now using
    //yaml-js to parse the scene files, and eventually the meta files.
    //TODO: THIS SHOULD BE PULLED INTO A GENERIC UNITY YAML FILE PARSER
    //INSIDE PARSERS.JS
    // async parseUnitySceneFileArray(file, fileLinesArr) {
    //   //   for (const file of this.allUnitySceneFilesHandles) {
    //   //add this new scene object to the scenesArray
    //   let sceneObj = {};
    //   sceneObj.name = file.name;
    //   sceneObj.objectsInSceneArray = [];
    //   this.scenesArray.push(sceneObj);

    //   //grab the scene file text here but don't save it to a persistent variable. so garbage collection cleans up
    //   //after the function exits. some Unity project can have 1000's of files.
    //   // let fileData = await file.handle.getFile();
    //   // let fileText = await fileData.text();

    //   //split each line into an array
    //   // let fileLinesArr = fileText.split(/\r\n|\r|\n/);

    //   //add the sceneObj as the first parent since this is the root
    //   let currentParentArrayArray = [];
    //   currentParentArrayArray.push(sceneObj.objectsInSceneArray);
    //   console.log('currentParentArrayArray', currentParentArrayArray);

    //   //if the next line is indent further, its a child object. if less its
    //   //a child of one the previous parent objects
    //   let prevLineNumOfSpaces = 0;

    //   //starting at index 2 because we can ignore the top 2 lines of the scene file. ie.:
    //   //%YAML 1.1
    //   //%TAG !u! tag:unity3d.com,2011:
    //   for (let i = 2; i < fileLinesArr.length; i++) {
    //     let line = fileLinesArr[i];

    //     //ignore lines with --- as these are just separators between objects in the yaml. Eg. --- !u!4 &1331370701
    //     if (line.startsWith('---')) {
    //       continue;
    //     }

    //     //skip any empty lines too. this can happen at the end of a scene file
    //     if (!line) {
    //       continue;
    //     }

    //     //Replace the - on lines so we can
    //     //count spaces correctly to know if its a child object
    //     //   m_Component:
    //     //   - component: {fileID: 1331370701}
    //     //   - component: {fileID: 1331370702}
    //     if (getFirstNonEmptyCharInString(line) === '-') {
    //       //https://bobbyhadz.com/blog/javascript-replace-first-occurrence-of-character-in-string
    //       line = line.replace(/-/, ' ');
    //       console.log('First char is a - Replaced with space');
    //       console.log('line: ', line);
    //     }

    //     //get the num of spaces before we trim any whitespace
    //     let numOfSpaces = getIndexOfFirstNonEmptyCharInString(line);
    //     console.log('numOfSpaces: ' + numOfSpaces);

    //     //Get the object key which is the string before the first :
    //     //and then the value which is the whole string afterwards, even if it contains more :
    //     //Eg. m_LocalRotation: {x: 0, y: 0, z: 0, w: 1}
    //     //stringBeforeColon = m_LocalRotation
    //     //stringAfterColon = {x: 0, y: 0, z: 0, w: 1}
    //     //https://stackoverflow.com/a/61124857/15546776
    //     let [stringBeforeColon, ...stringAfterColon] = line.split(':');
    //     stringAfterColon = stringAfterColon.join(':');

    //     //let keyValuePairArray = line.split(':');
    //     let keyValuePairArray = [stringBeforeColon, stringAfterColon];

    //     console.log(
    //       `keyValuePairArray. Key: ${keyValuePairArray[0]}. Value: ${keyValuePairArray[1]}. File: ${file.name}. Line: ${i}`
    //     );

    //     //eg: key = OcclusionCullingSettings
    //     //eg: value = ""
    //     //or    m_FlareStrength: 1
    //     let key = keyValuePairArray[0].trim();

    //     //not doing a trim() on the value yet as this will affect the check of it needing to be an array or not.
    //     let value = keyValuePairArray[1];
    //     let newObj = {};

    //     //if value is falsy then we know its an array of child objects
    //     //Eg. GameObject:
    //     if (!value) {
    //       value = [];
    //     }
    //     newObj[key] = value;

    //     //lines with less spaces than the previous line are not longer children
    //     //of the last currentParentArrayArray index.
    //     if (numOfSpaces < prevLineNumOfSpaces) {
    //       //
    //       //lines have have multiple indents compared to the previous line. And
    //       //only even number of spaces are used. Here the last line is indented
    //       //4 less than the previous line. and so it needs to be added not to
    //       //the previous parent array, but to the one before that.
    //       //Eg.
    //       //
    //       //    debug:
    //       //      m_Flags: 0
    //       //  m_NavMeshData: {fileID: 0}

    //       //Eg:
    //       //prevLineNumOfSpaces = 6
    //       //numOfSpaces = 2
    //       //6 -2 = 4
    //       //divide by 2 since we the format only deals in even number of spaces
    //       //4 % 2 = 2
    //       let numOfArrayPops = (prevLineNumOfSpaces - numOfSpaces) / 2;

    //       //now remove the previous parent array objects
    //       for (let j = 0; j < numOfArrayPops; j++) {
    //         currentParentArrayArray.pop();
    //       }

    //       console.log(
    //         'numOfSpaces is less, popping from array. array length = ' +
    //           currentParentArrayArray.length
    //       );
    //     }

    //     //get the correct parent array and push the new object
    //     let parentArray =
    //       currentParentArrayArray[currentParentArrayArray.length - 1];
    //     parentArray.push(newObj);

    //     //so this will be new array that the next object is pushed to
    //     //   NavMeshSettings: <- current parent array
    //     //     serializedVersion: 2
    //     //     m_ObjectHideFlags: 0
    //     //     m_BuildSettings: <- new parent array
    //     //       serializedVersion: 2
    //     if (Array.isArray(value)) {
    //       currentParentArrayArray.push(value);
    //     }

    //     prevLineNumOfSpaces = numOfSpaces;

    //     //Remove space after : using slice: https://www.techiedelight.com/remove-first-character-string-javascript/
    //     //All keyValuePair values start with a space after the : Eg, m_ObjectHideFlags: 0
    //     //First check that char 0 is indeed a space
    //     if (newObj[key][0] === ' ') {
    //       // console.log('first char is space. value = ' + newObj[key][0]);
    //       newObj[key] = newObj[key].slice(1);
    //       // console.log('newObj[key][0] after slice = ' + newObj[key][0]);
    //     }

    //     //convert the value to a number if possible, if not, it'll just return the original param passed in
    //     newObj[key] = tryParseNumber(newObj[key]);

    //     // try converting value to object if possible
    //     //   https://stackoverflow.com/questions/9637517/parsing-relaxed-json-without-eval
    //     newObj[key] = tryParseYamlJSONStyleObj(newObj[key]);

    //     //Grab any m_Script objects from the scene files. They looks like this:
    //     //  m_Script: {fileID: 11500000, guid: d92b62d5e02be4ffe8c2f3f0ddbca122, type: 3}
    //     //so we can loop over the array layer and get all the script file handles for every MonoBehaviour
    //     // if (key === 'm_Script') {
    //     //   this.allScriptObjsFromSceneFiles.push(newObj[key]);
    //     // }

    //     //Eg. does the line in the scene file look like this: GameObject:
    //     //If so, then add this to an array which we'll loop over later so
    //     //we can add any MonoBehaviour components to it
    //     if (key === 'GameObject') {
    //       //   this.allGOsFromSceneFiles.push(newObj[key]);
    //     }
    //   }
    //   //   }

    //   //   console.log('this.scenesArray', this.scenesArray);
    // },

    //Eg. Add the script handle here in the m_Script obj of the MonoBehaviour obj
    // MonoBehaviour:
    //   m_Script: {fileID: 11500000, guid: d92b62d5e02be4ffe8c2f3f0ddbca122, type: 3}
    // addScriptHandleToAllSceneFilesScriptObjs() {
    //   for (let i = 0; i < this.allScriptObjsFromSceneFiles.length; i++) {
    //     let scriptGUID = this.allScriptObjsFromSceneFiles[i].guid;
    //     console.log(
    //       `this.allScriptObjsFromSceneFiles[${i}].guid = ` + scriptGUID
    //     );
    //     this.allScriptObjsFromSceneFiles[i].scriptHandle = 'testScriptHandle';
    //     // console.log('here');
    //   }
    // },

    //For every m_Script obj in the scene files, add the cs fileHandle and fileName.
    //Note: the m_Script objects are found under MonoBehaviour: yaml objs in the scene files
    //Eg:
    //MonoBehaviour:
    // m_Script:
    // {
    //     fileID: 11500000,
    //     guid: d92b62d5e02be4ffe8c2f3f0ddbca122,
    //     type: 3,
    //     ============== IE: ADD THESE VALUES BELOW TO THE OBJECT
    //     fileHandle: fileHandle,
    //     fileName: "Test1.cs"
    // }
    addCsFileHandleAndNameToMScriptObjs() {
      console.log('addCsFileHandleAndNameToMScriptObjs() called');
      //   for (let i = 0; i < this.allScriptObjsFromSceneFiles.length; i++) {
      for (let i = 0; i < this.allMonoBehavioursArray.length; i++) {
        // let scriptObjInSceneFile = this.allScriptObjsFromSceneFiles[i];
        let scriptObjInSceneFile = this.allMonoBehavioursArray[i].m_Script;
        // let scriptGUID = this.allScriptObjsFromSceneFiles[i].guid;
        console.log(
          `addCsFileHandleAndNameToMScriptObjs() scriptGUID: ${scriptObjInSceneFile.guid}`
        );

        //Loop over all the cs meta files we grabbed earlier and check if the
        //m_Script guid matches the cs meta file guid. If so then add the cs
        //file handle from the meta file object to the m_Script object.
        for (let j = 0; j < this.allCsMetaFiles.length; j++) {
          let csMetaFile = this.allCsMetaFiles[j];
          let guidInMetaFile = csMetaFile.guid;

          //   console.log(
          //     `addCsFileHandleAndNameToMScriptObjs() csMetaFile: `,
          //     csMetaFile
          //   );
          //   console.log(
          //     `addCsFileHandleAndNameToMScriptObjs() guidInMetaFile: `,
          //     guidInMetaFile
          //   );

          if (guidInMetaFile === scriptObjInSceneFile.guid) {
            this.progressText = `Adding ${csMetaFile.name} file handle to scene file Monobehaviour`;
            // console.log(
            //   `addCsFileHandleAndNameToMScriptObjs(); found script for guid ${scriptObjInSceneFile.guid}. Script = ${csMetaFile.associatedFileHandle.name}`
            // );

            //we'll loop through these later and parse the cs file for function calls
            scriptObjInSceneFile.fileHandle = csMetaFile.associatedFileHandle;

            //just for easy debugging in the vue chrome extension
            scriptObjInSceneFile.fileName = csMetaFile.associatedFileName;

            //only break from the inner loop
            break;
          }
        }
      }
    },

    //Note: this step could be done in the addCsFileHandleAndNameToMScriptObjs()
    //above. But it's been separated out for readability

    //TODO: OPTIMIZATION. NO NEED TO PARSE THE SAME FILE TWICE. DO CHECK BY GUID
    //This is done after we parse the unity scene files so that we don't have to
    //parse all the cs files in the project. we only want to check the ones
    //which are included in the scenes.
    async parseCsFilesInScenesAndAddFuncNamesToMScriptObjs() {
      //   for (let i = 0; i < this.allScriptObjsFromSceneFiles.length; i++) {
      for (let i = 0; i < this.allMonoBehavioursArray.length; i++) {
        // let csFileHandle = this.allScriptObjsFromSceneFiles[i].fileHandle;
        let csFileHandle = this.allMonoBehavioursArray[i].m_Script.fileHandle;

        //TODO: IN addCsFileHandleAndNameToMScriptObjs() REMOVE THE SCRIPT OBJECTS WHICH
        //DONT HAVE A CS META FILE HANDLE SINCE THEY ARE BUILT-IN UNITY MONO'S. CLEANER THIS WAY
        //DO IT AS A SEPARATE FUNCTION.

        //some m_Script objects are part of built in Unity MonoBehaviours and therefore
        //we don't have the csFile handle for these components in the project. Eg, Event System, and Text components.
        //I'm keeping these types of Mono's in the array of objects as we may use them later when searching the scene objs.
        if (csFileHandle == undefined) {
          console.log(
            'parseCsFilesInScenesAndAddFuncNamesToMScriptObjs(); csFileHandle == undefined, continue;'
          );
          continue;
        }

        this.progressText = `Parsing ${csFileHandle.name} for Unity event functions`;

        let fileData = await csFileHandle.getFile();
        let fileText = await fileData.text();

        let funcNameAndLineNumArray = checkIfCodeFileHasFunctions(
          Consts.builtInUnityFunctionsArray,
          csFileHandle.name,
          fileText
        );
        // this.allScriptObjsFromSceneFiles[i].functions = funcNameAndLineNumArray;
        this.allMonoBehavioursArray[
          i
        ].m_Script.functions = funcNameAndLineNumArray;

        console.log(
          `parseCsFilesInScenesAndAddFuncNamesToMScriptObjs(); adding funcNameAndLineNumArray to allMonoBehavioursArray index ${i}. funcNameAndLineNumArray = `,
          funcNameAndLineNumArray
        );
      }
    },

    //Loops over all GameObjects in the scene file, checks its array of
    //components, then loops the scene file again and adds the specific component object
    //under the GameObject.
    //Eg, a GameObject: in a Unity scene file will have its components listed like this:

    //GameObject:
    //   m_Component:
    //   - component: {fileID: 1331370701}
    //   - component: {fileID: 1331370702}
    //

    //After adding the component objects, it will look like this:

    //GameObject:
    //   m_Component:
    //   - component: {
    //      fileID: 1331370701,
    //      MonoBehaviour: {
    //          //this is the monobehaviour found in the same scene file
    //      }
    //   },
    //   - component: {
    //      fileID: 1331370702,
    //      Transform: {
    //          //this is the Transform found in the same scene file
    //      }
    //   }
    addComponentObjsUnderGOs() {
      for (let i = 0; i < this.allScenesArray2.length; i++) {
        let scene = this.allScenesArray2[i];
        // console.log('scene name = ' + scene.sceneName);

        for (let j = 0; j < scene.objsInSceneArray.length; j++) {
          let sceneObj = scene.objsInSceneArray[j];

          //This object in the scene file is a GameObject. Eg: in scene file looks like this:
          //GameObject:
          //  m_ObjectHideFlags: 0
          if (sceneObj.GameObject != undefined) {
            let gameObject = sceneObj.GameObject;

            // console.log(
            //   `Found GameObject ${gameObject.m_Name} in ${scene.sceneName}`
            // );

            //Eg, this array under the GameObject:
            //m_Component:
            //   - component: {fileID: 398878359}
            //   - component: {fileID: 398878362}
            //   - component: {fileID: 398878361}
            //   - component: {fileID: 398878360}
            let gameObjectComponentsArray = gameObject.m_Component;
            console.log(`gameObject: `, gameObject);
            console.log(
              `gameObjectComponentsArray: `,
              gameObjectComponentsArray
            );

            //TODO: need to handle stripped GameObjects / prefabs.
            //Some scene file GO's may not have this array as they are 'stripped'.
            //Eg:
            //--- !u!1 &1208674853 stripped
            // GameObject:
            //More info here: https://forum.unity.com/threads/scene-files-invalid-yaml.355653/
            //Could be due to them being a prefab. Need to come back to this. Continue'ing for now.
            if (gameObjectComponentsArray == undefined) continue;

            for (let k = 0; k < gameObjectComponentsArray.length; k++) {
              let gameObjectComponent = gameObjectComponentsArray[k];
              let componentFileID = gameObjectComponent.component.fileID;
              console.log(
                `Found GO component. FileID = ${componentFileID} on GO: ${gameObject.m_Name} in ${scene.sceneName}`
              );

              //Now loop over all the objects in the scene again to find the object
              //with the id the same as componentFileID
              for (let l = 0; l < scene.objsInSceneArray.length; l++) {
                //Getting the 1st key value pair (0) from the object as there's only one. Eg:
                //{
                //  Transform: {
                //      uniqueObjIDInFile: 398878359
                //  }
                //}
                //https://stackoverflow.com/questions/3298477/get-the-first-key-name-of-a-javascript-object
                let [key, value] = Object.entries(scene.objsInSceneArray[l])[0];
                let uniqueObjIDInFile = value.uniqueObjIDInFile;

                // console.log(
                //   `Looping over all objs in scene file. Found key: ${key}. uniqueObjIDInFile = ${uniqueObjIDInFile}`
                // );

                //finally add the component to the GameObject if there's a match
                //Eg, does this line:
                //
                //Transform:
                //   uniqueObjIDInFile: 1331370701
                //
                //equal this line:
                //
                //GameObject:
                //   m_Component:
                //   - component: {fileID: 1331370701}
                if (uniqueObjIDInFile === componentFileID) {
                  gameObjectComponent.component[key] = value;
                  break;
                }

                this.progressText = `Adding scene file component ${key} under its GameObject parent`;
              }
            }
          }
        }
      }
    },

    //TODO: may not use the TreeView component, delete this function if not
    //Create the array of objects in a specific format for a Vuetify TreeView component:
    //https://vuetifyjs.com/en/components/treeview/#search-and-filter
    //Eg:
    //treeViewItemsArray: [
    // {
    //   id: 1,
    //   name: 'TestSCene',
    //   children: [
    //     {
    //       id: 2,
    //       name: 'Scripts GameObject',
    //       children: [
    //         {
    //           id: 201,
    //           name: 'MainGame.cs',
    //         },
    // fillTreeViewArrayForDisplayingData() {
    //   console.log(`fillTreeViewArrayForDisplayingData(); called`);

    //   //TODO / NOTE: this loop is the same used in addComponentObjsUnderGOs();
    //   //Separated it out here for readability / changability for now. However it might be
    //   //clean enough to role this function into addComponentObjsUnderGOs() as well
    //   //to save looping over all the scene objects twice, tbd.

    //   //for a veutify TreeView component a unique id is needed for filtering
    //   let treeViewObjUID = 0;

    //   for (let i = 0; i < this.allScenesArray2.length; i++) {
    //     console.log(
    //       `fillTreeViewArrayForDisplayingData(); -> for (let i = 0; i < this.allScenesArray2.length; i++)`
    //     );

    //     let scene = this.allScenesArray2[i];

    //     //a root TreeView scene object which gets pushed to the treeViewItemsArray
    //     let treeViewSceneObj = {
    //       id: treeViewObjUID,
    //       name: scene.sceneName,
    //       children: []
    //     };
    //     treeViewObjUID++;

    //     //foreach object which is listed in the unity scene yaml file
    //     for (let j = 0; j < scene.objsInSceneArray.length; j++) {
    //       let sceneObj = scene.objsInSceneArray[j];

    //       //We're only adding GameObject types under the root node as this replicates
    //       //a unity scene better. Ie. Unity scenes contain GO's, and GO's contain components.
    //       //Check if this object in the scene file is a GameObject.
    //       //Eg: in scene file looks like this:
    //       //
    //       //GameObject:
    //       //  m_ObjectHideFlags: 0
    //       if (sceneObj.GameObject != undefined) {
    //         let gameObject = sceneObj.GameObject;

    //         let treeViewGOObj = {
    //           id: treeViewObjUID,
    //           name: `${gameObject.m_Name}`,
    //           children: []
    //         };
    //         treeViewSceneObj.children.push(treeViewGOObj);
    //         treeViewObjUID++;

    //         //Get the m_Component array of GO object which can look like this:
    //         //m_Component:
    //         //   - component: {fileID: 398878359}, {MonoBehaviour: {m_Script: {//more nested objs}}}
    //         //   - component: {fileID: 398878362}, {Transform: {//more nested objs}}
    //         let gameObjectComponentsArray = gameObject.m_Component;

    //         //TODO: need to handle stripped GameObjects / prefabs.
    //         //Some scene file GO's may not have this array as they are 'stripped'.
    //         //Eg:
    //         //--- !u!1 &1208674853 stripped
    //         // GameObject:
    //         //More info here: https://forum.unity.com/threads/scene-files-invalid-yaml.355653/
    //         //Could be due to them being a prefab. Need to come back to this. Continue'ing for now.
    //         if (gameObjectComponentsArray == undefined) continue;

    //         for (let k = 0; k < gameObjectComponentsArray.length; k++) {
    //           let gameObjectComponent = gameObjectComponentsArray[k];

    //           let monobehaviour = gameObjectComponent.component.MonoBehaviour;

    //           //only checking for Monobehaviours at the moment, not other components (Transform, RenderSettings etc)
    //           if (monobehaviour != undefined) {
    //             //some Monobehaviors are built-in such as Text component. therefore we
    //             //don't have the associated cs fileName, so skip.
    //             if (monobehaviour.m_Script.fileName != undefined) {
    //               let treeViewGOComponentObj = {
    //                 id: treeViewObjUID,
    //                 name: monobehaviour.m_Script.fileName,
    //                 children: []
    //               };
    //               treeViewGOObj.children.push(treeViewGOComponentObj);
    //               treeViewObjUID++;

    //               //Some Monobehaviour game objects are built-in Unity ones such as
    //               //the Text component. Therefore we don't have an array of functions for these types
    //               let functionArray = monobehaviour.m_Script.functions;
    //               if (functionArray != undefined) {
    //                 for (let l = 0; l < functionArray.length; l++) {
    //                   let functionName = monobehaviour.m_Script.functions[l];

    //                   let treeViewFunctionNameObj = {
    //                     id: treeViewObjUID,
    //                     name: functionName,
    //                     children: []
    //                   };
    //                   treeViewGOComponentObj.children.push(
    //                     treeViewFunctionNameObj
    //                   );

    //                   console.log(
    //                     `fillTreeViewArrayForDisplayingData(); -> treeViewGOComponentObj.children.push(); treeViewGOComponentObj = `,
    //                     treeViewGOComponentObj
    //                   );

    //                   treeViewObjUID++;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //     this.treeViewItemsArray.push(treeViewSceneObj);
    //   }
    // },

    //Example of how objects should be laid out and added to the array
    // unityFuncsDataTableEntriesArray: [
    //   {
    //     entryNumber: 1,
    //     sceneName: 'MainGame',
    //     gameObject: 'Scripts holder',
    //     scriptFile: 'Main.cs',
    //     function: 'Awake'
    //   },
    //   {
    //     entryNumber: 2,
    //     sceneName: 'Menu',
    //     gameObject: 'Play Button',
    //     scriptFile: 'PlayButton.cs',
    //     function: 'Start'
    //   }
    loopOverGOFuncsAndfillDataTableArray() {
      console.log(`loopOverGOFuncsAndfillDataTableArray() called`);
      for (let i = 0; i < this.allScenesArray2.length; i++) {
        let scene = this.allScenesArray2[i];

        console.log(`loopOverGOFuncsAndfillDataTableArray() scene = `, scene);

        //foreach object which is listed in the unity scene yaml file. Eg:
        //--- !u!1 &1331370700
        // GameObject:
        // --- !u!4 &1331370701
        // Transform:
        // --- !u!114 &1331370702
        // MonoBehaviour:
        // m_ObjectHideFlags: 0
        for (let j = 0; j < scene.objsInSceneArray.length; j++) {
          let sceneObj = scene.objsInSceneArray[j];

          console.log(
            `loopOverGOFuncsAndfillDataTableArray() sceneObj = `,
            sceneObj
          );

          this.progressText = `Looping objects in scene file for final list ${i}`;

          //We're only adding GameObject types under the root DataTable node as this replicates
          //a unity scene better. Ie. Unity scenes contain GO's, and GO's contain components.
          //Check if this object in the scene file is a GameObject.
          //Eg: in scene file looks like this:
          //
          //GameObject:
          //  m_ObjectHideFlags: 0
          if (sceneObj.GameObject != undefined) {
            let gameObject = sceneObj.GameObject;

            console.log(
              `loopOverGOFuncsAndfillDataTableArray() gameObject = `,
              gameObject
            );

            //Get the m_Component array of GO object which can look like this:
            //m_Component:
            //   - component: {fileID: 398878359}, {MonoBehaviour: {m_Script: {//more nested objs}}}
            //   - component: {fileID: 398878362}, {Transform: {//more nested objs}}
            let gameObjectComponentsArray = gameObject.m_Component;

            //TODO: need to handle stripped GameObjects / prefabs.
            //Some scene file GO's may not have this array as they are 'stripped'.
            //Eg:
            //--- !u!1 &1208674853 stripped
            // GameObject:
            //More info here: https://forum.unity.com/threads/scene-files-invalid-yaml.355653/
            //Could be due to them being a prefab. Need to come back to this. Continue'ing for now.
            if (gameObjectComponentsArray == undefined) continue;

            for (let k = 0; k < gameObjectComponentsArray.length; k++) {
              let gameObjectComponent = gameObjectComponentsArray[k];

              let monobehaviour = gameObjectComponent.component.MonoBehaviour;

              //only checking for Monobehaviours at the moment, not other components (Transform, RenderSettings etc)
              if (monobehaviour != undefined) {
                //some Monobehaviors are built-in such as Text component. therefore we
                //don't have the associated cs fileName, so skip.
                if (monobehaviour.m_Script.fileName != undefined) {
                  console.log(
                    `loopOverGOFuncsAndfillDataTableArray() monobehaviour.m_Script.fileName = `,
                    monobehaviour.m_Script.fileName
                  );

                  //Some Monobehaviour game objects are built-in Unity ones such as
                  //the Text component. Therefore we don't have an array of functions for these types
                  let functionArray = monobehaviour.m_Script.functions;
                  if (functionArray != undefined) {
                    console.log(
                      `loopOverGOFuncsAndfillDataTableArray() functionArray = `,
                      functionArray
                    );
                    for (let l = 0; l < functionArray.length; l++) {
                      let functionObj = monobehaviour.m_Script.functions[l];

                      let dataTableEntry = {
                        index: this.unityFuncsDataTableEntriesArray.length + 1,
                        sceneName: scene.sceneName,
                        gameObject: gameObject.m_Name,
                        scriptFile: monobehaviour.m_Script.fileName,
                        function: functionObj.funcName,
                        lineNum: functionObj.lineNum
                      };
                      this.unityFuncsDataTableEntriesArray.push(dataTableEntry);

                      console.log(
                        `loopOverGOFuncsAndfillDataTableArray() this.unityFuncsDataTableEntriesArray.push(); this.unityFuncsDataTableEntriesArray = `,
                        this.unityFuncsDataTableEntriesArray
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    //How can I display a JavaScript object?
    //https://stackoverflow.com/a/4293047/15546776
    // displayScenesArray() {
    //   //   let str = JSON.stringify(this.scenesArray);
    //   //   this.scenesArrayAsJson = JSON.stringify(this.scenesArray, null, 4); // (Optional) beautiful indented output.
    //   this.scenesArrayAsJson = JSON.stringify(this.allScenesArray2, null, 4); // (Optional) beautiful indented output.
    //   //   console.log(this.scenesArrayAsJson); // Logs output to dev tools console.
    //   //   alert(str); // Displays output using window.alert()
    // }

    // showItemsInTreeView() {
    //   //   console.log('showItemsInTreeView()');
    // //   this.loopArrayValues(this.scenesArray);
    //   this.treeViewItemsArray = this.scenesArray;
    //   console.log('this.treeViewItemsArray', this.treeViewItemsArray);

    //   //   this.treeViewItemsArray = [
    //   //     {
    //   //     //   id: 1,
    //   //       name: 'Scene1',
    //   //       children: [
    //   //         {
    //   //         //   id: 2,
    //   //           name: 'GO!'
    //   //         }
    //   //       ]
    //   //     }
    //   //   ];

    //   //   for (let i = 0; i < this.scenesArray.length; i++) {
    //   //     // this.treeViewItemsArray.push(this.scenesArray[i]);

    //   //     if(typeof this.scenesArray[i] === 'object' && this.scenesArray[i] !== null){
    //   //         const [key, value] = this.scenesArray[i];
    //   //         console.log(key, value);
    //   //     }
    //   //   }

    //   //   Object.entries(this.scenesArray).forEach(entry => {
    //   //     const [key, value] = entry;
    //   //     console.log(key, value);
    //   //   });

    //   //1. DOESNT WORK try just passing the scenesArray to the treeView prop
    //   //2. or add to the treeViewItemsArray as the yaml file is being parsed
    //   //3. or iterate after parsing. eg, if value is array, then pass to recursive function, else, console log.
    // },

    // items: [
    //   {
    //     id: 1,
    //     name: 'Vuetify Human Resources',
    //     children: [
    //       {
    //         id: 2,
    //         name: 'Core team',
    //         children: [
    //           {
    //             id: 201,
    //             name: 'John'
    //           },
    //How to loop through objects and arrays:
    //https://masteringjs.io/tutorials/fundamentals/foreach-object
    // loopArrayValues(array) {
    //   for (let i = 0; i < array.length; i++) {
    //     if (typeof array[i] === 'object' && array[i] !== null) {
    //       //   const {key, value} = array[i];
    //       //   console.log('found object');
    //       //   console.log('array[i]', array[i]);

    //       Object.entries(array[i]).forEach(entry => {
    //         const [key, value] = entry;
    //         console.log(key, value);

    //         if (typeof value === 'string') {
    //           let newObj = {
    //             name: value
    //           };
    //           this.treeViewItemsArray.push(newObj);
    //         }

    //         if (Array.isArray(value)) {
    //           let newArray = [];
    //           let newObj = {
    //             children: newArray
    //           };
    //           this.treeViewItemsArray.push(newObj);
    //         }
    //         // //   console.log(key + ' is an array');

    //         //   this.loopArrayValues(value);
    //         // } else if (typeof value === 'object' && value !== null) {
    //         //   let newObj = {};
    //         //   newObj.name = "test";
    //         // //   newObj.name = key + value;
    //         //   this.treeViewItemsArray.push(newObj);
    //         // //   console.log('Added to this.treeViewItemsArray');
    //         // }
    //       });
    //     }
    //   }
    // }

    //=============NOTE: MAY GET RID OF THIS AND USE A GENERIC FUNCTION TO PARSE ALL OBJECTS FROM A SCENE FILE
    // async getGameObjectsInSceneFiles() {
    //   for (const file of this.allUnitySceneFilesHandles) {
    //     let fileData = await file.handle.getFile();
    //     let fileText = await fileData.text();

    //     //Get all the separate yaml objects in the scene file: https://docs.unity3d.com/Manual/FormatDescription.html
    //     let objsInSceneFile = fileText.split('---');

    //     for (var i = 0; i < objsInSceneFile.length; i++) {
    //       //   console.log(objsInSceneFile[i]);

    //       //split text by new lines
    //       //Note: this also counts any "\n" written in a string. Need to check for carriage return instead
    //       //https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
    //       let lines = objsInSceneFile[i].split(/\r\n|\r|\n/);

    //       //   console.log("lines",lines);

    //       //length check just for safety
    //       if (lines.length >= 1) {
    //         // console.log('lines.length >= 1');
    //         console.log('lines[0] = ', lines[0]);

    //         //if the first line contains !u!1 we know its a gameObject
    //         //Note: this space before and after the identifier has to be checked so we dont also
    //         //get other gameObjects starting with 1, eg, !u!187
    //         if (lines[0].startsWith(' !u!1 ')) {
    //           // console.log('found gameObject yaml object');
    //           //   console.log('file.gameObjects', file.gameObjects);

    //           //need to create the array before we can push to it
    //           if (file.gameObjects === undefined) {
    //             file.gameObjects = [];
    //           }

    //           //===================================================================================
    //           //Note: this can turn into quite a heavy js object. Need to consider how to not push all
    //           //the objsInSceneFile text into the file.gameObjects array. Perhaps a separate array which
    //           //can be wiped afterwards?
    //           //===================================================================================
    //           file.gameObjects.push(objsInSceneFile[i]);
    //         }
    //       }

    //       //   var lineWithoutSpaces = lines[i].replace(/\s+/g, '');

    //       //   for (var j = 0; j < Consts.heavyFunctionsToSearch.length; j++) {
    //       //     // console.log('Consts.heavyFunctionsToSearch = ' + Consts.heavyFunctionsToSearch[j]);

    //       //     if (lineWithoutSpaces.includes(Consts.heavyFunctionsToSearch[j])) {
    //       //       // console.log('GameObject.Find(');
    //       //       this.numOfUnityFuncsFound++;

    //       //       var dataTableEntry = {
    //       //         number: this.numOfUnityFuncsFound,
    //       //         name: file.name,
    //       //         function: Consts.heavyFunctionsToSearch[j],
    //       //         lineNumber: i + 1 //+1 because code files don't start at 0
    //       //       };
    //       //       this.unityFuncsDataTableEntriesArray.push(dataTableEntry);
    //       //     }
    //       //   }
    //     }
    //   }
    // },

    //Example of objText parameter
    // --- !u!1 &1331370700
    // GameObject:
    //   m_ObjectHideFlags: 0
    //   m_CorrespondingSourceObject: {fileID: 0}
    //   m_PrefabInstance: {fileID: 0}
    //   m_PrefabAsset: {fileID: 0}
    //   serializedVersion: 6
    //   m_Component:
    //   - component: {fileID: 1331370701}
    //   - component: {fileID: 1331370702}
    //   m_Layer: 0
    //   m_Name: Scripts
    //   m_TagString: Untagged
    //   m_Icon: {fileID: 0}
    //   m_NavMeshLayer: 0
    //   m_StaticEditorFlags: 0
    //   m_IsActive: 1
    // async parseUnitySceneFileObj(sceneObj, objText) {
    //   let fileLinesArr = objText.split(/\r\n|\r|\n/);

    //   //get the type of object which is the 2nd line (index 1). Eg:
    //   //line 0:    --- !u!1 &1331370700
    //   //line 1: GameObject:
    //   //and remove the last character
    //   //typeOfObj could be GameObject, Transform, AudioListener, Camera, etc etc.
    //   let typeOfObj = fileLinesArr[1].slice(0, -1);
    //   console.log('typeOfObj', typeOfObj);

    //   //need to create the array before we can push to it
    //   //using square bracket notation so that we get the string value of typeOfObj added as a property to the object
    //   //https://appdividend.com/2022/03/08/how-to-add-property-to-object-in-javascript/
    //   if (sceneObj[typeOfObj] === undefined) {
    //     sceneObj[typeOfObj] = [];
    //   }

    //   //push an empty object which we will fill with properties later
    //   let newSceneObj = {};
    //   sceneObj[typeOfObj].push(newSceneObj);

    //   // Starting at index position 0, remove the first two elements as these are not
    //   //part of the objects properties. Eg:
    //   //line 0:    --- !u!1 &1331370700
    //   //line 1: GameObject:

    //   fileLinesArr.splice(0, 2);
    //   await this.parseSceneFileObjProperties(newSceneObj, fileLinesArr);
    // },

    //parse the obj properties. lines are passed in the fileLinesArr parameter, eg:
    //   m_ObjectHideFlags: 0
    //   m_CorrespondingSourceObject: {fileID: 0}
    //   m_PrefabInstance: {fileID: 0}
    //   m_PrefabAsset: {fileID: 0}
    //   serializedVersion: 6
    //   m_Component:
    //   - component: {fileID: 1331370701}
    //   - component: {fileID: 1331370702}
    //   m_Layer: 0
    //   m_Name: Scripts
    //   m_TagString: Untagged
    //   m_Icon: {fileID: 0}
    //   m_NavMeshLayer: 0
    //   m_StaticEditorFlags: 0
    //   m_IsActive: 1
    // async parseSceneFileObjProperties(sceneObj, fileLinesArr) {
    //   //we know that if the next line has more spaces then it needs to be a child object / value
    //   let prevLineNumSpacesBeforeNonEmptyChar = 0;
    //   let parentObj = sceneObj;

    //   for (var i = 0; i < fileLinesArr.length; i++) {
    //     //Eg line looks like this:
    //     //m_LocalRotation: {x: 0, y: 0, z: 0, w: 1}
    //     let line = fileLinesArr[i];
    //     console.log('line: ', line);

    //     // let firstNonEmptyChar = getFirstNonEmptyCharInString(line);
    //     // console.log('firstNonEmptyChar: ', firstNonEmptyChar);

    //     // let numofSpaceAtStartOfLine = line.search(/\S|$/);
    //     // console.log('numofSpaceAtStartOfLine: ', numofSpaceAtStartOfLine);

    //     if (getFirstNonEmptyCharInString(line) === '-') {
    //       //https://bobbyhadz.com/blog/javascript-replace-first-occurrence-of-character-in-string
    //       line = line.replace(/-/, ' ');
    //       console.log('First char is a - Replaced with space');
    //       console.log('line: ', line);
    //     }

    //     let numSpacesBeforeNonEmptyChar = getIndexOfFirstNonEmptyCharInString(
    //       line
    //     );
    //     console.log(
    //       'prevLineNumSpacesBeforeNonEmptyChar',
    //       numSpacesBeforeNonEmptyChar
    //     );

    //     let isChildOfPrevLine = false;
    //     //if there's more spaces than the previous line, we know this is a child object
    //     if (numSpacesBeforeNonEmptyChar > prevLineNumSpacesBeforeNonEmptyChar) {
    //       isChildOfPrevLine = true;
    //       console.log('isChildOfPrevLine: ', isChildOfPrevLine);
    //     }

    //     prevLineNumSpacesBeforeNonEmptyChar = numSpacesBeforeNonEmptyChar;

    //     //get the key; the first part of the string before the : and trim any white space
    //     //Eg:
    //     //m_LocalRotation
    //     let propertyKey = line.substring(0, line.indexOf(':')).trim();

    //     //now get the value, after the :
    //     //+1 so we don't include the :
    //     //trim to remove any whitespace off the ends
    //     //Eg:
    //     //{x: 0, y: 0, z: 0, w: 1}
    //     let propertyValue = line
    //       .substring(line.indexOf(':') + 1, line.length)
    //       .trim();

    //     //Parse an object. Check if first and last char are { and }, therefore it's an object
    //     //Eg: {x: 0, y: 0, z: 0, w: 1}
    //     // if(propertyValue.charAt(0) === '{' && propertyValue.charAt(propertyValue.length - 1) === '}'){
    //     //     // console.log('Found an object value: ', propertyValue);

    //     //     //remove first and last character as these are { }
    //     //     //so we now have:
    //     //     //x: 0, y: 0, z: 0, w: 1
    //     //     propertyValue = propertyValue.slice(1, -1);

    //     //     //create the array by separating by , eg:
    //     //     //x: 0
    //     //     //y: 0
    //     //     //z: 0
    //     //     //w: 1
    //     //     let keyValuePairsArr = propertyValue.split(',');

    //     //     let newObj = {};

    //     //     for(var j = 0; j < keyValuePairsArr.length; j++){
    //     //         let singleKeyValuePairArr = keyValuePairsArr[j].split(':');
    //     //         newObj[singleKeyValuePairArr[0].trim()] = singleKeyValuePairArr[1].trim();
    //     //     }

    //     //     propertyValue = newObj;

    //     //     console.log('Found an object value. keyValuePairsArr: ', keyValuePairsArr);
    //     // }

    //     //if value is like this:
    //     //{x: 0, y: 0, z: 0, w: 1}
    //     //we'll parse it as a js object. Else this function will just return the string passed in
    //     propertyValue = tryParseYamlJSONStyleObj(propertyValue);

    //     parentObj[propertyKey] = propertyValue;
    //   }
    // }

    //Unity Yaml IDs: https://docs.unity3d.com/Manual/ClassIDReference.html
    // async getObjInUnitySceneFile(fileText, objYamlID) {

    //     //Get all the separate yaml objects in the scene file: https://docs.unity3d.com/Manual/FormatDescription.html
    //     let objsInSceneFile = fileText.split('---');

    //     for (var i = 0; i < objsInSceneFile.length; i++) {

    //       //split text by new lines
    //       //Note: this also counts any "\n" written in a string. Need to check for carriage return instead
    //       //https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
    //       let lines = objsInSceneFile[i].split(/\r\n|\r|\n/);

    //       if (lines[0].startsWith(objYamlID)) {
    //           console.log('found game object in unityScene file');
    //       }

    //     }
    // }
  }
};
</script>
