<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-1">
        <!-- <p>CS File Line Counter</!-->
        <h2>Unity Optimization Scanner</h2>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-2 mt-2">
        <v-btn @click="onChooseAssetFolderBtn" depressed color="primary">
          Choose Asset folder
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-2">
        <DataTableComp
          :mainTitle="'Heavy functions found: ' + entriesFound"
          :headers="dataTableHeaders"
          :data="dataTableEntries"
        >
        </DataTableComp>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { showDirPickerAndGetAllFilesOfType } from '@/js/fileNFolderUtils';
import DataTableComp from '@/components/DataTableComp.vue';
import { Analytics } from 'aws-amplify';
import Consts from '@/js/constants';

export default {
  name: 'OptimizationScanner',

  components: {
    DataTableComp
  },

  created() {
    this.resetValues();
  },

  data: () => ({
    files: null,
    entriesFound: 0,
    dataTableHeaders: [
      {
        text: '#',
        value: 'number'
      },
      {
        text: 'File name',
        value: 'name'
      },
      {
        text: 'Function',
        value: 'function'
      },
      {
        text: 'Line Number',
        value: 'lineNumber'
      }
    ],
    //placehold values added just as an example. these get deleted
    dataTableEntries: [
      {
        name: 'script1',
        function: 'GameObject.Find',
        lineNumber: 34
      },
      {
        name: 'script2',
        function: 'String.Concat',
        lineNumber: 822
      }
    ]
  }),

  methods: {
    resetValues() {
      //clear out the array
      this.dataTableEntries = [];
      this.entriesFound = 0;
      //   this.files = null;
      //   this.totalNumOfLines = 0;
    },
    async onChooseAssetFolderBtn() {
      Analytics.record({
        name: 'Optimization Scanner -> onChooseAssetFolderBtn'
      });
      this.resetValues();
      this.files = await showDirPickerAndGetAllFilesOfType('cs');
      await this.searchForTextInFiles();
    //   console.log('files', this.files);
      //   await this.getAllFiles();
    },

    async searchForTextInFiles() {
      //   this.files = await showDirPickerAndGetAllFilesOfType('cs');
      //   console.log('this.files', this.files);

      //   let totalNumOfLines = 0;
      for (const file of this.files) {
        //add the number of lines to the file object
        // file.numOfLines = await countLinesInFiles(file);
        // console.log('file with line count', file);
        // this.totalNumOfLines += file.numOfLines;

        let fileData = await file.handle.getFile();
        let fileText = await fileData.text();

        //split the file text by lines
        //Note: this also counts any "\n" written in a string. Need to check for carriage return instead
        //https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
        let lines = fileText.split(/\r\n|\r|\n/);

        for (var i = 0; i < lines.length; i++) {
        //   console.log('line ' + i + ') ' + lines[i]);

          var lineWithoutSpaces = lines[i].replace(/\s+/g, '');

          //   console.log('Consts.heavyFunctionsToSearch: ', Consts.heavyFunctionsToSearch);

          for (var j = 0; j < Consts.heavyFunctionsToSearch.length; j++) {
            // console.log('Consts.heavyFunctionsToSearch = ' + Consts.heavyFunctionsToSearch[j]);

            if (lineWithoutSpaces.includes(Consts.heavyFunctionsToSearch[j])) {
              // console.log('GameObject.Find(');
              this.entriesFound++;

              var dataTableEntry = {
                number: this.entriesFound,
                name: file.name,
                function: Consts.heavyFunctionsToSearch[j],
                lineNumber: i + 1 //+1 because code files don't start at 0
              };
              this.dataTableEntries.push(dataTableEntry);
            }
          }
        }
      }
      //   console.log('totalNumOfLines', totalNumOfLines);

      //   Analytics.record({
      //     name: 'Optimization Scanner -> getNumberOfLinesInFiles() done',
      //     attributes: {},
      //     metrics: { totalNumOfLines: totalNumOfLines }
      //   });
    }
  }
};
</script>
