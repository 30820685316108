//TODO: may not need to this component.
//Check if its being used.
<template>
  <v-card class="mx-auto" max-width="600" tile>
    <v-list dense>
      <!-- <v-subheader>REPORTS</v-subheader> -->
      <v-list-item-group v-model="selectedItem" color="primary">
        <!-- <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-icon>
            <v-icon v-text="fileIcon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: ['items'],
  data: () => ({
    selectedItem: 1,
    fileIcon: 'mdi-file'
    // items: [
    //   { text: 'Real-Time', icon: 'mdi-file' },
    //   { text: 'Audience', icon: 'mdi-account' },
    //   { text: 'Conversions', icon: 'mdi-flag' }
    // ]
  })
};
</script>
