<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-1">
        <!-- <p>CS File Line Counter</!-->
        <h2>CS File Line Counter</h2>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-2 mt-2">
        <v-btn @click="onChooseAssetFolderBtn" depressed color="primary">
          Choose Asset folder
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-2">
        <DataTableComp
          :mainTitle="'Total lines: ' + totalNumOfLines"
          :headers="dataTableHeaders"
          :data="dataTableEntries"
        >
        </DataTableComp>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  showDirPickerAndGetAllFilesOfType,
  countLinesInFiles
} from '@/js/fileNFolderUtils';
import DataTableComp from '@/components/DataTableComp.vue';
import { Analytics } from 'aws-amplify';

export default {
  name: 'LineCounter',

  components: {
    DataTableComp
  },

  created() {
    this.resetValues();
  },

  data: () => ({
    files: null,
    totalNumOfLines: 0,
    dataTableHeaders: [
      {
        text: 'File name',
        value: 'name'
      },
      {
        text: 'Line count',
        value: 'lineCount'
      }
    ],
    //placehold values added just as an example. these get deleted
    dataTableEntries: [
      {
        name: 'script1',
        lineCount: '101'
      },
      {
        name: 'script2',
        lineCount: '234'
      }
    ]
  }),

  methods: {
    async onChooseAssetFolderBtn() {
      Analytics.record({ name: 'LineCounter -> onChooseAssetFolderBtn' });
      this.resetValues();
      await this.getNumberOfLinesInFiles();
    },

    resetValues() {
      //clear out the array
      this.dataTableEntries = [];
      //   this.files = null;
      this.totalNumOfLines = 0;
    },

    async getNumberOfLinesInFiles() {
      this.files = await showDirPickerAndGetAllFilesOfType('cs');
      console.log('this.files', this.files);

      let totalNumOfLines = 0;
      for (const file of this.files) {
        //add the number of lines to the file object
        file.numOfLines = await countLinesInFiles(file);
        // console.log('file with line count', file);
        this.totalNumOfLines += file.numOfLines;

        var dataTableEntry = {
          name: file.name,
          lineCount: file.numOfLines
        };

        this.dataTableEntries.push(dataTableEntry);
      }
    //   console.log('totalNumOfLines', totalNumOfLines);

      Analytics.record({
        name: 'LineCounter -> getNumberOfLinesInFiles() done',
        attributes: {},
        metrics: { totalNumOfLines: totalNumOfLines }
      });
    }
  }
};
</script>
