//Deserialize a line like this: {x: 0, y: 0, z: 0, w: 1} into a js object.
//If stringValue can't be parsed, then just return stringValue. else return the new object
// export const tryParseYamlJSONStyleObj = stringValue => {
//   //incase a number or other type is passed in, exit early
//   if (typeof stringValue !== 'string') {
//     return stringValue;
//   }

//   //Check if first and last char are { and }, therefore it's an object
//   //Eg: {x: 0, y: 0, z: 0, w: 1}
//   if (
//     stringValue.charAt(0) === '{' &&
//     stringValue.charAt(stringValue.length - 1) === '}'
//   ) {
//     //remove first and last character as these are { }
//     //so we now have:
//     //x: 0, y: 0, z: 0, w: 1
//     stringValue = stringValue.slice(1, -1);

//     //create the array by separating by , eg:
//     //x: 0
//     //y: 0
//     //z: 0
//     //w: 1
//     let keyValuePairsArr = stringValue.split(',');

//     let newObj = {};

//     for (var j = 0; j < keyValuePairsArr.length; j++) {
//       //split the x: 0 index into its own array. Eg:
//       //x
//       //0
//       let singleKeyValuePairArr = keyValuePairsArr[j].split(':');

//       //add each key and value pair to the new object, and trim any whitespace.
//       //Eg object will look like this:
//       // {
//       //      x: 0
//       //      y: 0
//       //      z: 0
//       //      w: 1
//       // }
//       newObj[singleKeyValuePairArr[0].trim()] = singleKeyValuePairArr[1].trim();
//     }

//     // console.log('Found an object. keyValuePairsArr: ', keyValuePairsArr);
//     return newObj;
//   }

//   return stringValue;
// };

// export const getFirstNonEmptyCharInString = stringValue => {
//   for (let i = 0; i < stringValue.length; i++) {
//     if (stringValue[i] === ' ') {
//       continue;
//     }

//     return stringValue[i];
//   }
// };

// export const getIndexOfFirstNonEmptyCharInString = stringValue => {
//   for (let i = 0; i < stringValue.length; i++) {
//     if (stringValue[i] === ' ') {
//       continue;
//     }

//     return i;
//   }
// };

//if the string value passed in can be converted to a number, return the number
//else it will just return the original string.
//https://stackoverflow.com/a/21664614/15546776
// export const tryParseNumber = stringValue => {
//   let val = isFloat(stringValue);
//   if (val !== false) return val;

//   return stringValue;
// };

// function isFloat(val) {
//   var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
//   if (!floatRegex.test(val)) return false;

//   val = parseFloat(val);
//   if (isNaN(val)) return false;
//   return val;
// }

// function isInt(val) {
//   var intRegex = /^-?\d+$/;
//   if (!intRegex.test(val)) return false;

//   var intVal = parseInt(val, 10);
//   return parseFloat(val) == intVal && !isNaN(intVal);
// }

// export const tryParseYamlJSONStyleObj = stringValue => {
//   //incase a number or other type is passed in, exit early
//   if (typeof stringValue !== 'string') {
//     return stringValue;
//   }

//   //Check if first and last char are { and }, therefore it's an object
//   //Eg: {x: 0, y: 0, z: 0, w: 1}
//   if (
//     stringValue.charAt(0) === '{' &&
//     stringValue.charAt(stringValue.length - 1) === '}'
//   ) {
//     return parseRelaxJSON(stringValue);
//   }

//   return stringValue;
// };

//https://stackoverflow.com/a/39050609/15546776
// function parseRelaxJSON(stringValue) {
//   // var badJSON = '{one : "1:1", two : { three: \'3:3\' }}';

//   var fixedJSON = stringValue

//     // Replace ":" with "@colon@" if it's between double-quotes
//     .replace(/:\s*"([^"]*)"/g, function(match, p1) {
//       return ': "' + p1.replace(/:/g, '@colon@') + '"';
//     })

//     // Replace ":" with "@colon@" if it's between single-quotes
//     .replace(/:\s*'([^']*)'/g, function(match, p1) {
//       return ': "' + p1.replace(/:/g, '@colon@') + '"';
//     })

//     // Add double-quotes around any tokens before the remaining ":"
//     .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')

//     // Turn "@colon@" back into ":"
//     .replace(/@colon@/g, ':');

//     console.log('fixedJSON', fixedJSON);
//     console.log('fixedJSON pos 29', fixedJSON[29]);

//   return JSON.parse(fixedJSON);
// }

// export const tryParseYamlJSONStyleObj = stringValue => {
//   //incase a number or other type is passed in, exit early
//   if (typeof stringValue !== 'string') {
//     return stringValue;
//   }

//   //Check if first and last char are { and }, therefore it's an object
//   //Eg: {x: 0, y: 0, z: 0, w: 1}
//   if (
//     stringValue.charAt(0) === '{' &&
//     stringValue.charAt(stringValue.length - 1) === '}'
//   ) {
//     // const json = toJson(stringValue)
//     let correctJson = convertFromRelaxedJsonToJSON(stringValue);
//     let newObj = JSON.parse(correctJson);
//     return newObj;
//     //   return parseRelaxJSON(stringValue);
//   }

//   return stringValue;
// };

//Converts a relaxed json string like this: {fileID: 10001, guid: 0000000000000000e000000000000000, type: 0}
//to a valid json string. Ie, has quotes around keys and values
//For testing: http://www.relaxedjson.org/docs/converter.html
//Package: https://www.npmjs.com/package/really-relaxed-json
//Stackoverflow post: https://stackoverflow.com/a/48590627/15546776
// import { toJson } from 'really-relaxed-json';
// function convertFromRelaxedJsonToJSON(stringValue) {
//   return toJson(stringValue);
// }

//Eg, parse this line into an object:
//--- !u!1 &1331370700
//   export const parseObjIDFromYamlFile = stringValue => {
//     let valuesArray = stringValue.split(':');
//     let classOfYamlObj = valuesArray[1];
//     let objIDNumber = valuesArray[2];
//     let newObj = {
//         classOfYamlObj: classOfYamlObj,
//         objIDNumber: objIDNumber
//     }
//     return newObj;
//   }

//Expects a meta file string and returns a parsed js object
//Eg, parses a file like this:
// fileFormatVersion: 2
// guid: dffd9c57389834c67a35d7d0f695edf2
// DefaultImporter:
//   externalObjects: {}
//   userData:
//   assetBundleName:
//   assetBundleVariant:
// export const getGUIDFromUnityMetaFile = stringValue => {
//   console.log('getGUIDFromUnityMetaFile stringValue = ', stringValue);
//   //Note: this also counts any "\n" written in a string. Need to check for carriage return instead
//   //https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
//   let linesArray = stringValue.split(/\r\n|\r|\n/);
//   console.log('getGUIDFromUnityMetaFile linesArray = ', linesArray);

//   let secondLine = linesArray[1];
//   console.log('getGUIDFromUnityMetaFile secondLine = ', secondLine);

//   let secondLineArray = secondLine.split(' ');
//   console.log('getGUIDFromUnityMetaFile secondLineArray = ', secondLineArray);

//   let guid = secondLineArray[1];

//   //   let metaFileObj = {
//   //     guid: secondLineArray[1]
//   //   };

//   return guid;
// };

//Searches a text file to see if it contains certain functions
//and returns found functions as an array
//Note:fileName is only passed for debugging
export const checkIfCodeFileHasFunctions = (
  funcNameArray,
  fileName,
  fileText
) => {
  // console.log(
  //   `parseCsFileForFunctions() parsing file ${fileName} Text = `,
  //   fileText
  // );
//   console.log(`parseCsFileForFunctions() for functions`, funcNameArray);

  //split each line into an array
  let fileLinesArr = fileText.split(/\r\n|\r|\n/);

  //this will be returned to the calling function
  let functionsFoundArray = [];

  //loop over each line in the file. i = the line count
  for (let lineIndex = 0; lineIndex < fileLinesArr.length; lineIndex++) {
    //Eg: private void Start ()
    let line = fileLinesArr[lineIndex];

    //remove any whitespace at both ends of line
    line = line.trim();

    //Convert line to an array of words. Eg.
    //['private','void','Start','()',]
    let wordsInLineArray = line.split(' ');

    //loop through each word in the line
    for (let j = 0; j < wordsInLineArray.length; j++) {
      let word = wordsInLineArray[j];

      console.log(
        `parseCsFileForFunctions() word = ${word} on line ${lineIndex} in ${fileName}`
      );

      //remove extra whitespace. Eg a line could be "   //there are extra spaces before this comment"
    //   word = word.replace(/\s+/g, '');
      word = word.trim();

      //   console.log(
      //     `parseCsFileForFunctions() checking word ${word} on line ${i}`
      //   );

      //we want to ignore any words in the line after the //
      if (word.startsWith('//')) {
        console.log(`parseCsFileForFunctions() found comment // break; called`);
        break;
      }

      //remove spaces
      //https://stackoverflow.com/a/5963202/15546776
      // let lineWithoutSpaces = line.replace(/\s+/g, '');

      //now check if each word is equal to the function name passed in
      for (let k = 0; k < funcNameArray.length; k++) {
        let funcName = funcNameArray[k];
        //   let funcNameWithoutSpaces = funcName.replace(/\s+/g, '');

        //   console.log(
        //     `parseCsFileForFunctions() funcNameWithoutSpaces`,
        //     funcNameWithoutSpaces
        //   );
        //   console.log(
        //     `parseCsFileForFunctions() lineWithoutSpaces`,
        //     lineWithoutSpaces
        //   );

        //   if (lineWithoutSpaces.includes(funcName + '()')) {
        // if (line.includes(funcName)) {
        // if (word.includes(funcName)) {

        // console.log(
        //   `parseCsFileForFunctions() checking if word ${word} on line ${i} matches func: ${funcName}`
        // );

        //Parenthese '()' can be attached to the word so check for the case too
        if (word === funcName || word === funcName + '()' || word === funcName + '(') {
          // if (word.startsWith(funcName) || word.startsWith(funcName + '(')) {
            //   functionsFoundArray.push(funcName);
              functionsFoundArray.push({funcName: funcName, lineNum: lineIndex+1});

          console.log(
            `parseCsFileForFunctions() found ${funcName} in file ${fileName} on line ${lineIndex +
              1}. Line = ${fileLinesArr[lineIndex]}`, functionsFoundArray
          );

          //   break;
        }
      }
    }

    // console.log(
    //   `parseCsFileForFunctions() functionsFoundArray = `,
    //   functionsFoundArray
    // );
  }
  return functionsFoundArray;
};

//TODO: CREATE THE GENERIC UNITY SCENE FILE PARSER HERE USED FOR BOTH
//SCENE FILES AND META FILES. COULD DO THIS BY CONVERTING THE FILE STRING
//TO A LEGIT JSON OBJECT AND THEN USING THE BUILT IN FROMJSON DESERIALIZER
// export const parseUnityYamlFile = stringValue => {

// }
